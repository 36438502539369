import React from "react";
import { Link } from "gatsby";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";

const JobOpenings = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "account", // cta, account, null
        }}
      >
        <div className="inner-banner pt-29 pt-lg-30 pb-9 pb-lg-12 bg-default-6">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9">
                <div className="px-md-15 text-center">
                  <h2 className="title gr-text-2 mb-8 mb-lg-10 heading-color">
                    Full Stack Developer
                  </h2>
                  <p className="gr-text-7 mb-0 mb-lg-13 text-color-opacity">Full Time, Remote</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="main-block pb-6 pb-lg-17 bg-default-6">
          <Container>
            <Row className="justify-content-center">
              <Col xl="8" lg="9">
                <div className="single-block mb-12 mb-lg-15">
                  <p className="gr-text-9 text-color-opacity mb-9">
                    Associate Software Engineer (Entry-level/Fresher) | Remote-FriendlyAt AIN, we are embracing a 
                    remote-friendly work culture where everyone has the flexibility to either work remotely or from our office in USA.
                  </p>
                  <p className="gr-text-9 text-color-opacity mb-9">
                    We are looking for a versatile full-stack Javascript developer with proficiency in Node.js and React.js. 
                    You will get to design, architect and develop complex enterprise software and SaaS web applications leveraging modern web stack.
                  </p>
                </div>
                <div className="single-block mb-12 mb-lg-15">
                  <h3 className="gr-text-6 heading-color fw-bold mb-9">
                    What you’ll be doing
                  </h3>
                  <p className="gr-text-9 text-color-opacity mb-0">
                    Design & build highly scalable, high performance, responsive web applications.
                    Take full ownership and responsibility for building, shipping, and maintaining core product features, end to end. Help out in building the backend & front-end infrastructure.
                    Translation of requirements, designs and wireframes into high quality code. Collaborate closely with designers, engineers, founders and product managers.
                    Mentor team members and review their work.‍
                  </p>
                </div>
                <div className="single-block mb-12 mb-lg-14">
                  <h3 className="gr-text-6 heading-color fw-bold pb-3">
                    Skills &amp; Qualifications
                  </h3>
                  <ul className="list-unstyled">
                    <li className="mt-7 gr-text-9 text-color-opacity">
                      <i
                        className="rounded-circle bg-opposite d-inline-block me-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      1+years of production experience with NodeJS and ReactJS (and/or other modern web frameworks - Django, Golang, RoR)

                    </li>
                    <li className="mt-7 gr-text-9 text-color-opacity">
                      <i
                        className="rounded-circle bg-opposite d-inline-block me-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Experience with front-end technologies such as HTML5, CSS3, LESS, Bootstrap, Material UI, etc.

                    </li>
                    <li className="mt-7 gr-text-9 text-color-opacity">
                      <i
                        className="rounded-circle bg-opposite d-inline-block me-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Should be comfortable with database schema design and leveraging SQL & NoSQL (PostgreSQL, MySQL, Redis, Elasticsearch, DynamoDB)
                    </li>
                    <li className="mt-7 gr-text-9 text-color-opacity">
                      <i
                        className="rounded-circle bg-opposite d-inline-block me-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Experience with code quality and reusability practices (eslint, prettier, CI/CD for back-end & front-end repos)
                    </li>
                    <li className="mt-7 gr-text-9 text-color-opacity">
                      <i
                        className="rounded-circle bg-opposite d-inline-block me-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Strong understanding of software engineering best practices, including unit testing, code reviews, 
                      design documentation, debugging, troubleshooting, and agile development
                    </li>
                    
                    <li className="mt-7 gr-text-9 text-color-opacity">
                      <i
                        className="rounded-circle bg-opposite d-inline-block me-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Demonstrates ability to communicate effectively with the
                      rest of the team
                    </li>
                  </ul>
                </div>
                <div className="single-block mb-12 mb-lg-15">
                  <h3 className="gr-text-6 heading-color fw-bold mb-9">
                    How to apply
                  </h3>
                  <p className="gr-text-9 text-color-opacity mb-9">
                    We want to hire smart, curious and ambitious folks so please reach out even if you do not have all of the requisite experience. We are looking for engineers with the potential to grow!
                  </p>
                  <a href="mailto:info@ainintel.com" className="btn btn-primary mt-3 mt-lg-10">
                    Apply for this job
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default JobOpenings;
